.heart-icon {
  width: var(--size);
  position: relative;

  --no-color: rgba(244, 244, 244, 0);
  --size: 24px;
  --path-dasharray: 120;
}

.color-default {
  --default-color: rgb(197, 197, 197);
  --hover-color: var(--primary-color);
  --active-color: var(--primary-color);
  --active-hover-color: var(--primary-color);
}

.color-dark {
  --default-color: rgba(0, 0, 0, 0.7);
  --hover-color: rgba(0, 0, 0, 0.95);
  --active-color: var(--primary-color);
  --active-hover-color: var(--primary-color);
}

.heart-svg {
  width: 100%;
  position: relative;
  z-index: 2;
}

.heart-path {
  fill: var(--no-color);
  stroke: var(--default-color);
  stroke-dasharray: var(--path-dasharray);
  stroke-width: 1.5px;
  stroke-linecap: round;
}

.checkbox[type='checkbox'] {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0;
}

.checkbox[type='checkbox']:checked + svg .heart-path {
  stroke: var(--active-color);
  fill: var(--active-color);
}

.checkbox[type='checkbox']:checked:hover + svg .heart-path {
  stroke: var(--active-hover-color);
}

.checkbox[type='checkbox']:hover + svg .heart-path {
  stroke: var(--hover-color);
  transition: stroke 0.2s ease-in-out;
}

/* 不起作用，暂留 */
/* .collect-button:hover + svg .heart-path {
  stroke: var(--hover-color);
  transition: stroke 0.2s ease-in-out;
} */

/* 抖动 */
.animate_touch {
  animation: touch 0.7s forwards ease-in;
}

/* 填充 */
.animate_run {
  animation: run 0.75s 0.1s forwards linear;
}

/* 填充 */
@keyframes run {
  0% {
    stroke: var(--active-color);
    stroke-dashoffset: var(--path-dasharray);
    fill: var(--no-color);
  }

  80% {
    stroke-dashoffset: 0;
    fill: var(--active-color);
  }
  100% {
    stroke-dashoffset: 0;
    stroke: var(--active-color);
    fill: var(--active-color);
  }
}

/* 抖动 */
@keyframes touch {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.75);
  }
  75% {
    transform: scale(1.25);
  }
}
