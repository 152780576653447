.thumb-icon {
  width: var(--size);
  position: relative;

  --gray-color: rgba(0, 0, 0, 0.4);
  --no-color: rgba(244, 244, 244, 0);
  --brand-color: #c43737;
  --size: 24px;
  --path-dasharray: 120;
}

.thumb-svg {
  width: 100%;
  position: relative;
  z-index: 2;
}

.thumb-path {
  fill: var(--no-color);
  stroke: var(--gray-color);
  stroke-dasharray: var(--path-dasharray);
  stroke-width: 1.5px;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}

.checkbox[type='checkbox'] {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0;
}

.checkbox[type='checkbox']:checked + svg .thumb-path {
  stroke: var(--brand-color);
}

.checkbox[type='checkbox']:hover + svg .thumb-path {
  stroke: #000;
  transition: stroke 0.2s ease-in-out;
}

/* 抖动 */
.checkbox[type='checkbox'] + svg.animate {
  animation: touch 0.7s forwards ease-in;
}

/* 填充 */
.checkbox[type='checkbox'] + svg.animate .thumb-path {
  animation: run 0.75s 0.1s forwards linear;
}

@keyframes run {
  0% {
    stroke: var(--brand-color);
    stroke-dashoffset: var(--path-dasharray);
  }

  80% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke: var(--brand-color);
    stroke-dashoffset: 0;
  }
}

/* 抖动 */
@keyframes touch {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.75);
  }
  75% {
    transform: scale(1.25);
  }
}
