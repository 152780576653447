/* 展开 */
.album_pic_overlay_expand:hover::after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3) url(/img/audio-player/expand.svg) 50% no-repeat;
  background-size: 28px;
}

/* 收起 */
.album_pic_overlay_collapse:hover::after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3) url(/img/audio-player/collapse.svg) 50% no-repeat;
  background-size: 28px;
}

/* 歌词滚动样式 */
/****************************************/
.lrc::-webkit-scrollbar {
  width: 4px; /* 滚动条宽度 */
}

.lrc::-webkit-scrollbar-track {
  opacity: 0; /* 滚动条轨道透明度 */
}

/* 滚动条滑块颜色 */
.lrc::-webkit-scrollbar-thumb {
  border-radius: 3px; /* 滚动条圆角 */
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.3s;
  transition: all 0.3s;
}

/* 歌词区域hover 滑块颜色 */
.lrc:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.05);
}

/* 滚动条滑块悬停时颜色 */
.lrc::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

/* 播放列表滚动样式 */
/****************************************/
.playQueue::-webkit-scrollbar {
  width: 4px; /* 滚动条宽度 */
}

/* 滚动条轨道透明度 */
.playQueue::-webkit-scrollbar-track {
  opacity: 0;
}

/* 滚动条滑块颜色 */
.playQueue::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.05);
  transition: all 0.3s;
}

/* 播放列表 hover 滑块颜色 */
/* .playQueue:hover::-webkit-scrollbar-thumb { */
/* background: rgba(0, 0, 0, 0.05); */
/* } */

/* 滚动条滑块 hover */
.playQueue::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

.CD_animation {
  animation: spin 4.5s linear infinite;
}

.CD_animation_pause {
  animation-play-state: paused;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
