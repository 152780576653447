.SongList::-webkit-scrollbar {
  width: 4px;
}

.SongList::-webkit-scrollbar-track {
  opacity: 1;
}

.SongList::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.05);
  transition: all 0.3s;
}

.SongList::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}
