.equalizer {
  width: 24px;
  height: 24px;
  padding: 6px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.equalizer span {
  width: 2px;
  border-radius: 4px;
  background: #c43737ff;
  animation: up-and-down 2s linear infinite;
}

.equalizer span:nth-child(1) {
  animation-delay: 0.4s;
}

.equalizer span:nth-child(2) {
}

.equalizer span:nth-child(3) {
  animation-delay: 0.7s;
}

.equalizer.paused span {
  animation-play-state: paused;
}

@keyframes up-and-down {
  0%,
  100% {
    clip-path: inset(27% 0 0 0);
  }

  10% {
    clip-path: inset(17% 0 0 0);
  }

  20% {
    clip-path: inset(55% 0 0 0);
  }

  30% {
    clip-path: inset(30% 0 0 0);
  }

  40% {
    clip-path: inset(13% 0 0 0);
  }

  50% {
    clip-path: inset(38% 0 0 0);
  }

  60% {
    clip-path: inset(80% 0 0 0);
  }

  70% {
    clip-path: inset(21% 0 0 0);
  }

  80% {
    clip-path: inset(0% 0 0 0);
  }

  90% {
    clip-path: inset(36% 0 0 0);
  }
}
