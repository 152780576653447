/* 播放进度条 */
.ProgressBarRoot {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  height: 11px;
  cursor: pointer;
}

.ProgressBarTrack {
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;
  flex-grow: 1;
  height: 2px;
  border-radius: 2px;
  overflow: hidden;
}

.ProgressBarRange {
  position: absolute;
  background-color: #181818;
  height: 2px;
}

.ProgressBarThumb {
  display: block;
  width: 11px;
  height: 11px;
  background-color: #181818;
  border-radius: 50%;
  outline: none;
}
.ProgressBarThumb:hover {
  /* background-color: var(--violet-3); */
}

/* 音量条 */
/******************************************************************************************************/
.VolumeBarRoot {
  display: flex;
  align-items: center;
  position: relative;
}

.VolumeBarRoot[data-orientation='vertical'] {
  flex-direction: column;
  height: 102px;
  border-radius: 37px;
}

.VolumeBarTrack {
  position: relative;
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.1);
}

.VolumeBarTrack[data-orientation='vertical'] {
  width: 2px;
  border-radius: 2px;
}

/* 激活区域 */
.VolumeBarRange {
  position: absolute;
  background-color: #646464;
}
.VolumeBarRange[data-orientation='vertical'] {
  width: 100%;
  border-radius: 2px;
}

/* 滑块 */
.VolumeBarThumb {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #646464;
  outline: none;
}

.VolumeBarRoot:hover .VolumeBarRange {
  background-color: rgba(0, 0, 0, 0.95);
}

.VolumeBarRoot:hover .VolumeBarThumb {
  background-color: rgba(0, 0, 0, 0.95);
}

.VolumeBarContainer {
  box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.1);
}

.VolumeBarContainer::before {
  content: '';
  display: block;
  position: absolute;
  left: calc(-50% - 0.7px);
  top: calc(-50% - 0.7px);
  border-radius: 24px;
  width: calc(200% + 1.4px); /* 考虑到阴影会占用额外空间，增加 1.4px */
  height: calc(200% + 1.4px); /* 考虑到阴影会占用额外空间，增加 1.4px */
  box-shadow: 0 0 0 0.7px #e1e1e1; /* 使用阴影模拟边框 */
  transform: scale(0.5);
  pointer-events: none; /** 防止子元素点击事件失效 */
  overflow: hidden;
}

.SongList::-webkit-scrollbar {
  width: 4px;
}

.SongList::-webkit-scrollbar-track {
  opacity: 1;
}

.SongList::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.05);
  transition: all 0.3s;
}

.SongList::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}
