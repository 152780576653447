/** Dropdown 滚动条 */
/******************************************************************************************************/
.dropdownScrollbar::-webkit-scrollbar {
  width: 4px; /* 滚动条宽度 */
}

/* 滚动条轨道透明度 */
.dropdownScrollbar::-webkit-scrollbar-track {
  margin: 24px 0 40px 0;
  opacity: 0;
}

/* 滚动条滑块颜色 */
.dropdownScrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}

/* 滚动条滑块 hover */
.dropdownScrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}
